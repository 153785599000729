<section class="brochure" style="background: #fff">
  <div class="dealer-details">
    <div class="container">
    <div class="row">
      <div class="col col-sm-2">
        <img class="dealer-logo img-fluid"
             style="width: 130px;"
             src="{{car.branchLogoUrl}}"
             onerror="this.onerror=null;this.src='../assets/images/default_logo.png'"
             alt="{{car.branchTradingName}}">
      </div>
      <div class="col col-sm-10">
        <h4>{{car.branchTradingName}}</h4>
        <ul>
          <li>
            <strong>{{car.region || 'unknown region'}}</strong>
          </li>
          <li>
            <fa-icon [icon]="phoneIcon" style="margin-right: 10px"></fa-icon>
            <a href="tel:{{car.branchContactNumber}}" target="_blank">{{car.branchContactNumber || 'n/a'}}</a>
          </li>
          <li>
            <fa-icon [icon]="emailIcon" style="margin-right: 10px"></fa-icon>
            <a href="mailto:{{car.branchEmailAddress}}" target="_blank">{{car.branchEmailAddress || 'n/a'}}</a>
          </li>
          <li style="display: flex">
            <fa-icon [icon]="linkIcon" style="margin-right: 10px"></fa-icon>
            <div >
              <a href="{{car.branchWebsiteUrl}}" target="_blank">{{car.branchWebsiteUrl.replace('https://', '')  || 'n/a'}}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  </div>
  <div class="body-container">
    <div class="container">
      <div class="row">
        <div class="col-md-12 mobile-center"
             style="align-self:center;">
          <div>
          <h2>{{car.make}}
            <span class="secondary-title">{{car.variant}}</span>
          </h2>
          </div>
          <h4 class="vehicle-price" >{{car.sellingPrice | currency:'R': 'symbol' : '1.0'}}</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 col-lg-9">
          <div class="row" style="background: #ebebeb; padding-top: 15px; margin: 0">
            <div class="col-md-6">
              <div>

                <p-galleria [value]="slides"
                            [numVisible]="4"
                            [circular]="true"
                            [containerStyle]="{ 'max-width': '850px' }"
                            [showItemNavigators]="true"
                            [responsiveOptions]="responsiveOptions">
                  <ng-template pTemplate="item" let-item let-rowindex="rowindex">
                    <img [src]="item" style="width: 100%;" (click)="imageClick(item)" />
                  </ng-template>
                  <ng-template pTemplate="thumbnail" let-item>
                    <div class="grid grid-nogutter justify-content-center">
                      <img [src]="item" style="padding: 8px" (click)="imageClick(item)" />
                    </div>
                  </ng-template>
                </p-galleria>
                <p-galleria
                  [value]="slides"
                  [(visible)]="displayCustom"
                  [(activeIndex)]="activeIndex"
                  [containerStyle]="{ 'max-width': '850px' }"
                  [numVisible]="7"
                  [circular]="true"
                  [fullScreen]="true"
                  [showItemNavigators]="true"
                  [showThumbnails]="false" >
                  <ng-template pTemplate="item" let-item>
                    <img [src]="item" style="width: 100%; display: block;" />
                  </ng-template>
                </p-galleria>
              </div>

                <div style="margin-top: 10px">
                  <div class="social-shares">

                    <div class="row m-0 gap-1">
                      <h5 class="col">Share:</h5>
                      <div class="col" style="background-color: #0866FF" >
                        <a [href]="facebookLink" target="_blank"><i class="pi pi-facebook"></i></a>
                      </div>
                      <div class="col" style="background-color: #1D9BF0" >
                        <a [href]="twitterLink" target="_blank"><i class="pi pi-twitter"></i></a>
                      </div>
                      <div class="col" style="background-color: #25D366">
                        <a [href]="whatsAppLink" target="_blank"><i  class="pi pi-whatsapp"></i></a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="details-sidebar">
                  <h4>Description:</h4>
                  <div class="" >
                    <p style="text-align: left" [innerHtml]="car.description || 'Currently no description'"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div class="col-md-8 col-lg-3" *ngIf="!mobileTabs">
          <div class="accordion" id="form" >
            <div class="btn" id="formhead1" style="width: 100%;">
              <h4 data-bs-toggle="collapse" data-bs-target="#form1"
                  aria-expanded="true" aria-controls="form1">Enquire now</h4>
            </div>
            <div id="form1" class="collapse show" aria-labelledby="form1" data-parent="#form">
              <app-brochure-enquiry [carData]="car"></app-brochure-enquiry>
            </div>
            <div class="btn" id="formhead2" style="width: 100%;">
              <h4 class="collapsed" data-bs-toggle="collapse" data-bs-target="#form2"
                  aria-expanded="true" aria-controls="form2">Apply for Finance</h4>
            </div>
            <div style="position: absolute" id="form2" class="collapse" aria-labelledby="formhead2" data-parent="#form">
              <app-brochure-finance-enquiry [car]="car"></app-brochure-finance-enquiry>
            </div>
          </div>
        </div>
      </div>

      <div class="row specs mt-3">
        <div class="col-md-6" >
          <h4> Specifications:</h4>
          <div>
            <ul class="details"
                style="column-count: 2; padding-bottom: 0">
              <li>
                <strong>Year: </strong>{{car.modelYear || 'Year not specified'}}
              </li>
              <li>
                <strong>Mileage: </strong>{{car.mileage || '0' | number }}Km
              </li>
              <li>
                <strong>Colour: </strong>{{car.color || 'Colour not specified'}}
              </li>
              <li>
                <strong>Body type: </strong>{{car.bodyType|| 'Body Type not specified'}}
              </li>
              <li>
                <strong>Province: </strong> {{car.region || 'Province not specified'}}
              </li>
              <li>
                <strong>Fuel Type: </strong> {{car.stockInformationFuelType || 'Fuel Type not set' }}
              </li>
            </ul>
            <div class="accordion" id="spec">
              <div id="more-specs" class="collapse" aria-labelledby="more-specs" data-parent="#spec">
                <ul class="details"
                    style="column-count: 2">
                  <li>
                    <strong>Height: </strong> {{car.stockInformationHeight || '0' | number}}cm
                  </li>
                  <li>
                    <strong>Width: </strong> {{car.stockInformationWidth || '0' | number }}cm
                  </li>
                  <li>
                    <strong>Length: </strong> {{car.stockInformationLength || '0' | number}}cm
                  </li>
                  <li>
                    <strong>Gears: </strong> {{car.stockInformationGears || 'Gears not specified'}}
                  </li>
                  <li>
                    <strong>Transmission: </strong> {{car.stockInformationTransmission || 'Transmission not specified'}}
                  </li>
                  <li>
                    <strong>Cooling: </strong> {{car.stockInformationCooling || 'Cooling not specified'}}
                  </li>
                  <li>
                    <strong>Front tyre size: </strong> {{car.stockInformationFrontTyreSize || 'Front tyre size not specified'}}
                  </li>
                  <li>
                    <strong>Rear tyre size: </strong> {{car.stockInformationRearTyreSize || 'Rear tyre size not specified'}}
                  </li>
                  <li>
                    <strong>Axle Configuration: </strong> {{car.stockInformationAxleConfiguration || 'Axle Configuration not specified'}}
                  </li>
                  <li>
                    <strong>Tare: </strong> {{car.stockInformationTares || '0' | number}}Kg
                  </li>
                  <li>
                    <strong>GVM: </strong> {{car.stockInformationGvm || '0' | number}}Kg
                  </li>
                  <li>
                    <strong>GCM: </strong> {{car.stockInformationGcm || '0' | number}}Kg
                  </li>
                  <li>
                    <strong>Seats: </strong> {{car.stockInformationSeats || 'Seats not specified'}}
                  </li>
                  <li>
                    <strong>Doors: </strong> {{car.stockInformationDoors || 'Doors not specified'}}
                  </li>
                </ul>

              </div>
              <div class="btn" id="more-specs1" style="width: 100%;" data-bs-toggle="collapse" data-bs-target="#more-specs"
                   aria-expanded="false" aria-controls="more-specs">
                <h4 class="more">
                  <i class = "fa fa-angle-down"></i>
                  More Specifications
                </h4>
                <h4 class="less">
                  <i class = "fa fa-angle-up"></i>
                  Less Specifications
                </h4>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <h4> Extras:</h4>
          <div>
            <ul class="details extras-list"
                style="column-count: 1; padding-bottom: 0" >
              <li class="pl-1" *ngFor="let extra of car?.extras">
                {{extra.name || 'Extras not specified'}}
              </li>
              <li *ngIf="car.extras === null" class="empty-list">
                -- No Extras --
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-md-12 mt-2">
        <div class="forms-block" *ngIf="mobileTabs">
          <p-tabView [scrollable]="true">
            <p-tabPanel header="Enquire Now">
              <app-brochure-enquiry class="brochure_tab" [carData]="car"></app-brochure-enquiry>
            </p-tabPanel>
            <p-tabPanel header="Apply For Finance">
              <app-brochure-finance-enquiry class="brochure_tab" [car]="car"></app-brochure-finance-enquiry>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    </div>
    <br>

    <div class="recommended-block">
      <div class="">
        <div class="container">
          <div class="row">
            <div class="col-md-12 carousel">
              <h4 class="subtitle fancy"><span>More Cars from {{car.branchTradingName}}</span></h4>
              <div class="row">
                <div *ngFor="let car of recommendedCars | truthify"
                   class="col-md-3 col-6">
                <div class="car-container" style="cursor: pointer"
                     [routerLink]="['/vehicle', car.id, car.make]"
                     (click)="scrollToTop()">
                  <div class="img-container">
                    <img [lazyLoad]="car?.imageUrl"
                         [defaultImage]="defaultImage"
                         width="100%">
                  </div>
                  <h5 style="text-transform:lowercase">
                    <strong style="text-transform:uppercase">{{car.make}}</strong> {{car.masterModel}}</h5>
                  {{car.mileage}} km's <span style="font-weight: 600"> | </span>
                  {{car.sellingPrice | currency:'R': 'symbol' : '1.0'}}
                </div>
              </div>
              </div>
              <div class="text-center" > <br>
                <div>
                  <a class="btn-allcars" style="background: #bbb;" (click)="goToAllCars()" href="{{car.branchWebsiteUrl}}"  target="_blank">All Cars</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
